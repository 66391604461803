import React, { useEffect, useState } from "react";
import Staking1 from "../components/Staking1";
import Staking2 from "../components/Staking2";
import Staking3 from "../components/Staking3";
import Staking4 from "../components/Staking4";
import Staking5 from "../components/Staking5";
import Staking6 from "../components/Staking6";
import Staking7 from "../components/Staking7";

const Staking = ({ connectMetamaskWallet, metaMaskAddress, setMetamaskAddress }) => {
  const targetDate = new Date("November 18, 2024 14:00:00 GMT+0530"); // your target date and time
  const calculateTimeRemaining = () => {
    const now = new Date();
    const timeDifference = targetDate - now;

    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {}, []);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>

  const handleAccounts = async () => {
    // To Check Metamask is connected after page refreshing
    try {
      // biome-ignore lint/correctness/noInnerDeclarations: <explanation>
      // biome-ignore lint/style/noVar: <explanation>
      var MetamaskAccount = await window.ethereum.request({
        method: "eth_accounts"
      });
      if (MetamaskAccount?.length > 0) {
        setMetamaskAddress(MetamaskAccount[0]);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    handleAccounts();
  }, []);

  return (
    <div className="container max-w-[1320px] mx-auto flex flex-col gap-16 py-32">
      <section>
        <div className="flex flex-wrap  md:justify-center  md:gap-5 items-center  gap-2 mb-8">
          <h1 className=" text-gradient  font-syne md:text-[26px] md:mb-3 text-[36px] font-bold staking-heading1">
            Staking $wBRGE
          </h1>
          <div className="text-[#A689FF] font-grostek rounded-2xl total-staking-box bg-none px-3 py-1.5">
            {" "}
            Ends in {timeRemaining.days}D {timeRemaining.hours}H {timeRemaining.minutes}m{" "}
            {timeRemaining.seconds}s
          </div>
        </div>
        <Staking7 metaMaskAddress={metaMaskAddress} connectMetamaskWallet={connectMetamaskWallet} />
        <Staking6 metaMaskAddress={metaMaskAddress} connectMetamaskWallet={connectMetamaskWallet} />
        <Staking5 metaMaskAddress={metaMaskAddress} connectMetamaskWallet={connectMetamaskWallet} />
        <Staking4 metaMaskAddress={metaMaskAddress} connectMetamaskWallet={connectMetamaskWallet} />
        <Staking3 metaMaskAddress={metaMaskAddress} connectMetamaskWallet={connectMetamaskWallet} />
        <Staking2 metaMaskAddress={metaMaskAddress} connectMetamaskWallet={connectMetamaskWallet} />
        <Staking1 metaMaskAddress={metaMaskAddress} connectMetamaskWallet={connectMetamaskWallet} />

        <p className="sm:text-[10px] md:text-[12px] text-[14px] opacity-50 text-center text-white my-3">
          <span className="font-semibold">No Lock-in Period</span>, Unstake your{" "}
          <span className="font-semibold">$wBRGE</span> anytime with no penalties.
        </p>
      </section>
    </div>
  );
};

export default Staking;
